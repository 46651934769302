/* eslint-disable no-plusplus */
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'

import { resolveSsrNameByCode } from '@/constants/selectOptions'
import { SOURCES_CAN } from '@/constants/reservation'

import {
  convertISODateTime,
  convertISODateTimeToLLLL,
  getTransitTime,
  toHoursAndMinutes,
  resolveAirlineFlightNumber,
  cutHonorificFromFirstName,
  getMinTime,
} from '@core/utils/filter'

// 21/3: them baggageAllow vao
function addBookingCodeAndTransitTime(itineraries, bookingData) {
  const { bookingCode, status, source } = bookingData
  const baggageAllow = bookingData?.paxLists[0]?.baggageAllowances
  const itinerariesCount = itineraries.length
  for (let i = 0; i < itinerariesCount; i++) {
    const segmentsCount = itineraries[i].length

    for (let j = 0; j < segmentsCount; j++) {
      itineraries[i][j].bookingCode = bookingCode
      itineraries[i][j].bookingStatus = status
      itineraries[i][j].source = source
      itineraries[i][j].baggageAllow = baggageAllow
      itineraries[i][
        j
      ].trip = `${itineraries[i][j].departure.iataCode}-${itineraries[i][j].arrival.iataCode}`
      if (j === segmentsCount - 1) {
        itineraries[i][j].transitTime = null
      } else {
        itineraries[i][j].transitTime = getTransitTime(
          itineraries[i][j + 1].departure.at,
          itineraries[i][j].arrival.at,
        )
      }
    }
  }
}

function getTripBySegmentId(itineraries, { segmentId, itinerariesCount }, type = 'BAGGAGE') {
  let result = ''
  itineraries.forEach(itinerary => {
    itinerary.forEach(seg => {
      if ((seg.segmentId === segmentId) && (itinerariesCount === itinerary[0].itinerariesCount)) {
        if (type === 'BAGGAGE') {
          if (seg.source === 'TH') {
            result = seg.segmentId
          } else if (itinerary.length === 1) {
            result = `${itinerary[0].departure.iataCode}-${itinerary[0].arrival.iataCode}`
          } else if (itinerary.length > 1) {
            const trip = []
            itinerary.forEach((seg, index) => {
              if (index === 0) {
                trip.push(seg.departure.iataCode)
                trip.push(seg.arrival.iataCode)
              } else {
                trip.push(seg.arrival.iataCode)
              }
            })
            result = trip.join('-')
          }
        } else {
          result = `${seg.departure.iataCode}-${seg.arrival.iataCode}`
        }
      }
    })
  })
  return result
}

function getAirlineBySegmentId(itineraries, segmentId) {
  const itinerariesCount = itineraries.length
  for (let i = 0; i < itinerariesCount; i++) {
    const segmentsCount = itineraries[i].length

    for (let j = 0; j < segmentsCount; j++) {
      const idName = itineraries[i][j].airline === 'VJ' ? 'segmentId' : 'segmentId' // VJ: itineraryId
      if (itineraries[i][j][idName] === segmentId) {
        return itineraries[i][j].airline
      }
    }
  }

  return ''
}

function getSsrsName(item, type = 'BAGGAGE', source, locale = 'vi') {
  let result
  const ssr = (item.airline !== 'VJ' && item.serviceSubCode)
    ? resolveSsrNameByCode(item.serviceSubCode, locale)
    : (['VN1A_MT', 'VN1A', 'TH', '1A'].includes(source) || (['QH'].includes(source) && (type !== 'BAGGAGE')))
      ? resolveSsrNameByCode(item.serviceCode, locale)
      : resolveSsrNameByCode(item.serviceInfo.description, locale)
  const quantity = item.numberOfItems === 0 ? 1 : item.numberOfItems

  if (type === 'BAGGAGE') {
    result = `${ssr} x${quantity}`
  } else {
    result = ssr
  }

  return result
}

export function getTicketTotalPayment(ticketData) {
  const {
    totalAmountPaid,
    totalAmountToBePaid,
    // totalFee,
    totalFeeService,
    // totalParentFee,
  } = ticketData

  return (
    totalAmountPaid
    + totalAmountToBePaid
    // FIXME: Dũng BE bảo bỏ tạm đi để fix VN bị x2 giá
    // + totalFee
    + totalFeeService
    // + totalParentFee
  )
}

function resolveAllItineraries(itineraries, IndexCombinateTicket, supplierPNRs) {
  return itineraries?.map(t => t?.map(s => ({ ...s, itinerariesCount: IndexCombinateTicket, supplierPNRs }))) || []
}

function resolveAllAncillaryServices(ancillaries, IndexCombinateTicket, bookingData) {
  return ancillaries?.map(ancillary => {
    const paxOfAncillary = bookingData?.paxLists.find(pax => pax?.paxId === ancillary?.paxId)
    // eslint-disable-next-line prefer-template
    const paxName = paxOfAncillary ? (paxOfAncillary?.lastName + ' ' + paxOfAncillary?.firstName + ' ' + paxOfAncillary?.title) : null
    return {
      ...ancillary,
      itinerariesCount: IndexCombinateTicket,
      source: bookingData.source,
      paxName,
    }
  }) || []
}

export function useParseTicketData(ticketData, combinate = false, locale = 'vi') {
  const {
    itineraries,
    // ssr,
    ancillaryServices,
    roundtripBooking,
    paxLists,
    isDomestic,
    pricingInfo,
    source,
  } = ticketData

  const multiCityBookings = ticketData?.multiCityBookings?.filter(booking => !['CANCEL'].includes(booking?.status)) || null

  addBookingCodeAndTransitTime(itineraries, ticketData)

  if (roundtripBooking && combinate) {
    addBookingCodeAndTransitTime(
      roundtripBooking.itineraries,
      ticketData.roundtripBooking,
    )
  } else if (multiCityBookings && multiCityBookings.length && combinate) {
    multiCityBookings.forEach(multiCityBooking => {
      addBookingCodeAndTransitTime(
        multiCityBooking.itineraries,
        multiCityBooking,
      )
    })
  }
  let allItineraries
  let allSsr
  let timeHold = ticketData?.timeHold

  if (roundtripBooking && combinate) {
    allItineraries = [
      ...resolveAllItineraries(itineraries, 0, ticketData?.supplierPNRs),
      ...resolveAllItineraries(roundtripBooking.itineraries, 1, roundtripBooking?.supplierPNRs),
    ]
    allSsr = [
      ...resolveAllAncillaryServices(ancillaryServices, 0, ticketData),
      ...resolveAllAncillaryServices(roundtripBooking.ancillaryServices, 1, roundtripBooking),
    ]
    timeHold = getMinTime([timeHold, roundtripBooking.timeHold])
  } else if (multiCityBookings && multiCityBookings.length && combinate) {
    const multiCityBookingsItineraries = []
    multiCityBookings.forEach((multiCityBooking, index) => multiCityBookingsItineraries.push(...resolveAllItineraries(multiCityBooking.itineraries, index + 1, multiCityBooking?.supplierPNRs)))
    allItineraries = [
      ...resolveAllItineraries(itineraries, 0, ticketData?.supplierPNRs),
      ...multiCityBookingsItineraries,
    ]
    const multiCityBookingsSsr = []
    multiCityBookings.forEach((multiCityBooking, index) => {
      if (multiCityBooking.ancillaryServices?.length) {
        multiCityBookingsSsr.push(...resolveAllAncillaryServices(multiCityBooking.ancillaryServices, index + 1, multiCityBooking))
      }
    })
    allSsr = [...resolveAllAncillaryServices(ancillaryServices, 0, ticketData), ...multiCityBookingsSsr]
    timeHold = getMinTime([
      timeHold,
      ...multiCityBookings
        .filter(multiCityBooking => multiCityBooking?.timeHold)
        .map(multiCityBooking => multiCityBooking.timeHold),
    ])
  } else {
    allItineraries = resolveAllItineraries(itineraries, 0, ticketData?.supplierPNRs)
    allSsr = resolveAllAncillaryServices(ancillaryServices, 0, ticketData)
  }

  // Sort the allItineraries array
  allItineraries.sort((a, b) => {
    const departureA = new Date(a[0].departure.at)
    const departureB = new Date(b[0].departure.at)
    return departureA - departureB
  })

  const flights = allItineraries.map(itinerary => {
    const segmentsCount = itinerary.length

    // bookingCode
    const bookingCode = itinerary[0].bookingCode
    const bookingStatus = itinerary[0].bookingStatus

    // trip
    const trip = `${itinerary[0].departure.iataCode}-${
      itinerary[segmentsCount - 1].arrival.iataCode
    }`

    // supplierPNRs
    const supplierPNRs = itinerary[0].supplierPNRs
    // segments
    const segments = itinerary.map(segment => {
      const from = segment.departure.iataCode
      const to = segment.arrival.iataCode

      const departTime = convertISODateTime(
        segment.departure.at,
        segment.departure.timeZone,
      ).time
      const departDate = convertISODateTime(
        segment.departure.at,
        segment.departure.timeZone,
      ).date
      const departWeekday = convertISODateTimeToLLLL(
        segment.departure.at,
        segment.departure.timeZone,
        locale,
      ).weekday
      const arrivalTime = convertISODateTime(
        segment.arrival.at,
        segment.arrival.timeZone,
      ).time
      const arrivalDate = convertISODateTime(
        segment.arrival.at,
        segment.arrival.timeZone,
      ).date
      const arrivalWeekday = convertISODateTimeToLLLL(
        segment.arrival.at,
        segment.arrival.timeZone,
        locale,
      ).weekday
      let durationHM = toHoursAndMinutes(segment.duration)
      if (locale === 'en') {
        durationHM = durationHM.replace(/ phút$/, 'min(s)')
        durationHM = durationHM.replace(/p$/, 'min(s)')
        durationHM = durationHM.replace(/h/, 'hr(s) ')
      }

      const airline = segment.airline
      const flight = `${resolveAirlineFlightNumber(airline, segment.flightNumber)}`
      const transitTime = segment.transitTime

      return {
        segmentNotModified: { ...segment, source: itinerary[0].source },
        from,
        to,
        startTerminal: segment.startTerminal || segment.departure?.terminal,
        endTerminal: segment.endTerminal || segment.arrival?.terminal,
        departTime,
        departDate,
        departWeekday,
        arrivalTime,
        arrivalDate,
        arrivalWeekday,
        durationHM,
        airline,
        flight,
        transitTime,
        operating: segment.operating,
        airCraft: segment.airCraft,
        mileage: segment.mileage,
        groupClass: segment?.groupClass,
        bookingClass: segment?.bookingClass,
        supplierPNRs,
        source: segment?.source,
      }
    })

    return {
      bookingCode,
      bookingStatus,
      trip,
      segments,
      supplierPNRs,
      source: segments[0].source,
    }
  })

  // Sort the allSsr array
  const sortSsr = ssrArray => sortBy(ssrArray, ({ trip }) => {
    const tripArray = allItineraries.flat().map(i => i.trip)
    const index = tripArray.indexOf(trip)
    return index === -1 ? Infinity : index
  })

  // 1G thì ko hiện dịch vụ bổ sung với Bag và Other service
  const allSsrBagTemp = allSsr.filter(item => item.serviceType === 'BAGGAGE' && item.source !== '1G')

  allSsrBagTemp.forEach(item => {
    item.trip = getTripBySegmentId(allItineraries, { segmentId: item.segmentIds[0], itinerariesCount: item.itinerariesCount })
    if (!item?.airline) item.airline = getAirlineBySegmentId(allItineraries, item.segmentIds[0])
    item.xBagName = getSsrsName(item, 'BAGGAGE', item.source, locale)
    // if (locale === 'en') {
    //   item.xBagName = item.xBagName.replace(/Gói hành lý/, 'Bag').replace(/xách tay bổ sung/, 'Cabin bag')
    // }
  })
  const allSsrBag = sortSsr(allSsrBagTemp)

  const allOtherSsrTemp = allSsr.filter(item => !['BAGGAGE', 'SEAT'].includes(item.serviceType) && item.source !== '1G')
  allOtherSsrTemp.forEach(item => {
    item.trip = getTripBySegmentId(allItineraries, { segmentId: item.segmentIds[0], itinerariesCount: item.itinerariesCount }, 'OTHERS')
    if (!item?.airline) item.airline = getAirlineBySegmentId(allItineraries, item.segmentIds[0])
    item.otherName = getSsrsName(item, 'OTHERS', item.source, locale)
  })

  const allOtherSsr = sortSsr(allOtherSsrTemp)

  const allSeatSsrTemp = allSsr.filter(item => ['SEAT'].includes(item.serviceType))
  allSeatSsrTemp.forEach(item => {
    item.trip = getTripBySegmentId(allItineraries, { segmentId: item.segmentIds[0], itinerariesCount: item.itinerariesCount }, 'SEAT')
    if (!item?.airline) item.airline = getAirlineBySegmentId(allItineraries, item.segmentIds[0])
    item.seatName = `Số ghế ${item.pdcSeat}`
    if (locale === 'en') {
      item.seatName = item.seatName.replace(/Số ghế/, 'Seat number')
    }
  })
  const allSeatSsr = sortSsr(allSeatSsrTemp)

  const paxs = paxLists.map(pax => {
    const { firstName, honorific } = cutHonorificFromFirstName(pax.firstName)
    let title = ''
    const titleDraft = pax.title || honorific
    if (['MR', 'MRS', 'MS', 'MISS', 'MSTR'].includes(titleDraft)) title = titleDraft
    else if (pax.gender) {
      title = pax.paxType === 'ADULT' ? (pax.gender === 'MALE' ? 'MR' : 'MRS') : (pax.gender === 'MALE' ? 'MSTR' : 'MISS')
    } else {
      ['MR', 'MRS', 'MS', 'MISS', 'MSTR'].forEach(item => {
        if (`${pax.lastName} ${pax.firstName}`.includes(item)) {
          title = item
        }
      })
    }
    const paxType = pax.paxType
    let bags = null
    let others = null
    let seat = null

    if (paxType !== 'INFANT') {
      // FIXME paxid đang bị khác nhau
      bags = allSsrBag.filter(item => {
        if (item.paxId === pax.paxId) return true
        const paxName = `${pax?.lastName} ${pax?.firstName} ${pax?.title}`
        return paxName === item.paxName
      })
      others = allOtherSsr.filter(item => {
        if (item.paxId === pax.paxId) return true
        const paxName = `${pax?.lastName} ${pax?.firstName} ${pax?.title}`
        return paxName === item.paxName
      })
      seat = allSeatSsr.filter(item => {
        if (item.paxId === pax.paxId) return true
        const paxName = `${pax?.lastName} ${pax?.firstName} ${pax?.title}`
        return paxName === item.paxName
      })
    }

    const mappingEtickets = (etickets, source) => etickets
      .filter(item => item.ticketType === 'TKT' && ['OK', 'OPEN', 'CKIN', 'CTRL'].includes(item.status) && !SOURCES_CAN.NOT_SHOW_TICKET_NUMBER.includes(item.source))
      .map(item => ({ number: item?.number, source: source.slice(0, 2) }))

    const findPaxTicketNumber = (pax, paxLists, source) => {
      const fullName = `${pax?.lastName} ${pax?.firstName}`
      // chỉ lọc lấy pax cùng tên
      const paxListsByFullName = paxLists
        .filter(item => {
          const fName = `${item?.lastName} ${item?.firstName}`
          return fName.includes(fullName) || fullName.includes(fName)
        })
        .map(item => ({ ...item, eticket: mappingEtickets(item.eticket, source) }))
      return paxListsByFullName
    }
    const allPax = [{ ...pax, eticket: mappingEtickets(pax.eticket, source) }]
    if (combinate) {
      allPax.push(...(findPaxTicketNumber(pax, ticketData?.roundtripBooking?.paxLists ?? [], ticketData?.roundtripBooking?.source)))
      if (ticketData?.multiCityBookings?.length) {
        // eslint-disable-next-line no-unused-expressions
        ticketData?.multiCityBookings?.forEach(booking => {
          allPax.push(...(findPaxTicketNumber(pax, booking?.paxLists ?? [], booking.source)))
        })
      }
      if (ticketData?.relationBookings?.length) {
        // eslint-disable-next-line no-unused-expressions
        ticketData?.relationBookings?.forEach(booking => {
          allPax.push(...(findPaxTicketNumber(pax, booking?.paxLists ?? [], booking.source)))
        })
      }
    }

    const ticketNumber = uniqBy(allPax.map(pax => pax.eticket).flat(), 'number')

    return {
      passenger: {
        paxType,
        title,
        firstName,
        lastName: pax.lastName,
        ticketNumber,
        travelFlyer: `${pax.travelFlyer?.tier ?? ''} ${pax.travelFlyer?.provider ?? ''} ${pax.travelFlyer?.number ?? (pax.travelFlyer && !isEmpty(pax.travelFlyer) ? pax.travelFlyer : '')}`.trim(),
      },
      bags,
      others,
      seat,
    }
  })

  const vatRateValue = isDomestic ? 0.1 : 0
  let totalPayment = getTicketTotalPayment(ticketData)

  if (roundtripBooking) {
    totalPayment += getTicketTotalPayment(roundtripBooking)
  } else if (multiCityBookings && multiCityBookings.length) {
    multiCityBookings.forEach(multiCityBooking => {
      totalPayment += getTicketTotalPayment(multiCityBooking)
    })
  }

  const totalAmountBeforeTaxFloat = totalPayment / (1 + vatRateValue)
  const totalAmountBeforeTax = Math.round(totalAmountBeforeTaxFloat)

  const price = {
    vatRateValue,
    totalAmountBeforeTax,
    currency: pricingInfo.currency,
  }
  // line BB

  return {
    bookingId: ticketData.id,
    timeHold: timeHold ? convertISODateTime(timeHold).dateTime : '',
    flights,
    paxs: sortBy(paxs, ['passenger.paxType',
      //  'passenger.ticketNumber'
    ]),
    price,
  }
}
